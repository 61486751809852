const like = "rgb(233,121,21)";
const superLike = "rgb(64, 150, 255)";
const match = "rgb(142, 68, 173)";
const premium = "rgb(230, 126, 34)";
const compatibility = "rgb(64, 150, 255)";

export default {
  like,
  superLike,
  match,
  premium,
  compatibility,
};

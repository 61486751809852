import {
  ApiTwoTone,
  CrownTwoTone,
  ExperimentTwoTone,
  FlagOutlined,
  HeartTwoTone,
  ThunderboltTwoTone,
} from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";

import commonColors from "./commonColors";

const Like = (props: AntdIconProps) => (
  <HeartTwoTone twoToneColor={commonColors.like} {...props} />
);

const SuperLike = (props: AntdIconProps) => (
  <ThunderboltTwoTone twoToneColor={commonColors.superLike} {...props} />
);

const Match = (props: AntdIconProps) => (
  <ApiTwoTone twoToneColor={commonColors.match} {...props} />
);

const Premium = (props: AntdIconProps) => (
  <CrownTwoTone twoToneColor={commonColors.premium} {...props} />
);

const Compatibility = (props: AntdIconProps) => (
  <ExperimentTwoTone twoToneColor={commonColors.compatibility} {...props} />
);

const Lite = (props: AntdIconProps) => <FlagOutlined {...props} />;

export const CommonSymbology = {
  Like,
  SuperLike,
  Match,
  Premium,
  Compatibility,
  Lite,
};
